import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { favoriteTeamsSaga } from './saga';
import { FavoriteTeamsState } from './types';
import { FavoriteTeam, Team } from '../../../../types/Team';

export const initialState: FavoriteTeamsState = {
  favoriteTeams: [],
  getLoading: false,
  addLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name: 'favoriteTeams',
  initialState,
  reducers: {
    getFavoriteTeams(state) {
      state.getLoading = true;
    },
    getFavoriteTeamsSuccess(state, action: PayloadAction<FavoriteTeam[]>) {
      state.getLoading = false;
      state.favoriteTeams = action.payload;
    },
    getFavoriteTeamsError(state) {
      state.getLoading = false;
    },

    addFavoriteTeam(state, action: PayloadAction<number>) {
      state.addLoading = true;
    },
    addFavoriteTeamSuccess(state, action: PayloadAction<FavoriteTeam>) {
      state.addLoading = false;
      state.favoriteTeams = [action.payload, ...state.favoriteTeams];
    },
    addFavoriteTeamError(state) {
      state.addLoading = false;
    },

    deleteFavoriteTeam(state, action: PayloadAction<number>) {
      state.deleteLoading = true;
    },
    deleteFavoriteTeamSuccess(state, action: PayloadAction<number>) {
      state.deleteLoading = false;
      state.favoriteTeams = state.favoriteTeams.filter(
        ({ team }) => team.id !== action.payload,
      );
    },
    deleteFavoriteTeamError(state, action: PayloadAction<number>) {
      state.deleteLoading = false;
    },
  },
});

export const { actions: favoriteTeamsActions } = slice;

export const useFavoriteTeamsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: favoriteTeamsSaga });
  return { actions: slice.actions };
};
