import _mapValues from 'lodash/mapValues';
import { createStyledBreakpointsTheme } from 'styled-breakpoints';
import { breakpoints } from '../../media';

const fontSize = 16;

export const common = {
  flexa: {
    columns: 12,
    breakpoints,
  },
  ...createStyledBreakpointsTheme({
    breakpoints: _mapValues(
      breakpoints,
      breakpoint => `${breakpoint * fontSize}px` as `${string}px`,
    ),
  }),
};
