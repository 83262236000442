import { v4 as uuidv4 } from 'uuid';
import { Preferences } from '@capacitor/preferences';

const ACCESS_TOKEN_COOKIE = 'auth.accessToken';
const REFRESH_TOKEN_COOKIE = 'auth.refreshToken';
const DEVICE_ID_COOKIE = 'auth.deviceId';
const FCM_TOKEN_COOKIE = 'auth.fcmToken';

export async function createTokenCookies(
  accessToken: string,
  refreshToken: string,
) {
  await Preferences.set({ key: ACCESS_TOKEN_COOKIE, value: accessToken });
  await Preferences.set({ key: REFRESH_TOKEN_COOKIE, value: refreshToken });
}

export async function removeTokenCookies() {
  await Preferences.remove({ key: ACCESS_TOKEN_COOKIE });
  await Preferences.remove({ key: REFRESH_TOKEN_COOKIE });
}

export async function getAccessToken() {
  const { value } = await Preferences.get({ key: ACCESS_TOKEN_COOKIE });
  return value;
}

export async function getRefreshToken() {
  const { value } = await Preferences.get({ key: REFRESH_TOKEN_COOKIE });
  return value;
}

export async function createDeviceId() {
  const deviceId = uuidv4();
  await Preferences.set({ key: DEVICE_ID_COOKIE, value: deviceId });
}

export async function getDeviceId() {
  const { value } = await Preferences.get({ key: DEVICE_ID_COOKIE });
  return value;
}

export async function removeDeviceId() {
  await Preferences.remove({ key: DEVICE_ID_COOKIE });
}

export async function createFCMToken(fcmToken) {
  await Preferences.set({ key: FCM_TOKEN_COOKIE, value: fcmToken });
}

export async function getFCMToken() {
  const { value } = await Preferences.get({ key: FCM_TOKEN_COOKIE });
  return value;
}

export async function removeFCMToken() {
  await Preferences.remove({ key: FCM_TOKEN_COOKIE });
}
