import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reportSectionsSaga } from './saga';
import {
  CreateReportSectionAction,
  DeleteReportSectionAction,
  ReportSectionsState,
  UpdateReportSectionAction,
} from './types';
import { ReportSectionEntry } from '../../types';

export const initialState: ReportSectionsState = {
  reportSections: [],
  getLoading: false,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name: 'reportSections',
  initialState,
  reducers: {
    getReportSections(state) {
      state.getLoading = true;
    },
    getReportSectionsSuccess(
      state,
      action: PayloadAction<ReportSectionEntry[]>,
    ) {
      state.getLoading = false;
      state.reportSections = action.payload;
    },
    getReportSectionsError(state) {
      state.getLoading = false;
    },

    createReportSection(
      state,
      action: PayloadAction<CreateReportSectionAction>,
    ) {
      state.createLoading = true;
    },
    createReportSectionSuccess(
      state,
      action: PayloadAction<ReportSectionEntry>,
    ) {
      state.createLoading = false;
      state.reportSections = [...state.reportSections, action.payload];
    },
    createReportSectionError(state) {
      state.createLoading = false;
    },

    updateReportSection(
      state,
      action: PayloadAction<UpdateReportSectionAction>,
    ) {
      state.updateLoading = true;
    },
    updateReportSectionSuccess(
      state,
      action: PayloadAction<ReportSectionEntry>,
    ) {
      state.updateLoading = false;
      state.reportSections = state.reportSections.map(reportSection =>
        reportSection.id === action.payload.id ? action.payload : reportSection,
      );
    },
    updateReportSectionError(state) {
      state.updateLoading = false;
    },

    deleteReportSection(
      state,
      action: PayloadAction<DeleteReportSectionAction>,
    ) {
      state.deleteLoading = true;
    },
    deleteReportSectionSuccess(state, action: PayloadAction<number>) {
      state.deleteLoading = false;
      state.reportSections = state.reportSections.filter(
        reportType => reportType.id !== action.payload,
      );
    },
    deleteReportSectionError(state) {
      state.deleteLoading = false;
    },
  },
});

export const { actions: reportSectionsActions } = slice;

export const useReportSectionsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reportSectionsSaga });
  return { actions: slice.actions };
};
