import { css } from 'styled-components';

import { themeProvider } from '../../theme';

const { theme } = themeProvider;

export const mediaQuery = props =>
  themeProvider.breakpointsKeys(props).reduce((accumulator, value) => {
    const breakpointSize = theme(props).breakpoints[value];

    Object.assign(accumulator, {
      [value]: (...args) => {
        if (value === 'print') {
          return css`
            @media print {
              ${css(...args)}
            }
          `;
        }

        return breakpointSize === 0
          ? css`
              ${css(...args)}
            `
          : css`
              @media screen and (min-width: ${breakpointSize}rem), print {
                ${css(...args)}
              }
            `;
      },
    });

    return accumulator;
  }, {});

export default mediaQuery;
