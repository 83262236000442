import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import teamSvgLight from './assets/team-light.svg';
import leagueSvgLight from './assets/league-light.svg';
import teamSvgDark from './assets/team-dark.svg';
import leagueSvgDark from './assets/league-dark.svg';
import { selectThemeKey } from '../../../styles/theme/slice/selectors';
import { isSystemDark } from '../../../styles/theme/utils';

interface ImgProps {
  src?: string | undefined;
  alt?: string | undefined;
  imgSize: number;
  placeholder?: 'league' | 'team';
}

function ImgOrigin({ placeholder, src, ...props }: ImgProps) {
  const [imageSrc, setImageSrc] = useState(src);
  const ref = useRef<HTMLImageElement>(null);
  const themeKey = useSelector(selectThemeKey);

  const theme = useMemo(() => {
    if (themeKey === 'system') {
      return isSystemDark ? 'dark' : 'light';
    }

    return themeKey;
  }, [themeKey]);

  const onError = () => {
    if (placeholder) {
      switch (placeholder) {
        case 'team': {
          setImageSrc(theme === 'dark' ? teamSvgLight : teamSvgDark);
          break;
        }
        case 'league': {
          setImageSrc(theme === 'dark' ? leagueSvgLight : leagueSvgDark);
          break;
        }
      }
    }
  };

  const onLoad = () => {
    if (
      placeholder &&
      ref?.current?.naturalHeight === 1 &&
      ref?.current?.naturalWidth === 1
    ) {
      onError();
    }
  };

  useEffect(() => {
    src?.includes('/0.png') ? onError() : setImageSrc(src);
  }, [src]);

  return (
    <ImgStyled
      src={imageSrc}
      onError={onError}
      onLoad={onLoad}
      ref={ref}
      {...props}
    />
  );
}

export const ImgStyled = styled.img.attrs(({ ref }) => ({
  ref: ref,
}))<ImgProps>`
  max-width: ${props => props.imgSize}px;
  max-height: ${props => props.imgSize}px;
  min-width: ${props => props.imgSize}px;
  min-height: ${props => props.imgSize}px;
  object-fit: contain;
`;

export const Img = React.memo<ImgProps>(ImgOrigin);
