import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AxiosError } from 'axios';

import { recentSearchesSaga } from './saga';
import {
  AddRecentSearchAction,
  RecentSearch,
  RecentSearchCoach,
  RecentSearchesState,
} from './types';

export const initialState: RecentSearchesState = {
  coaches: [],
  getLoading: false,
  addLoading: false,
};

const slice = createSlice({
  name: 'recentSearches',
  initialState,
  reducers: {
    getCoaches(state, action: PayloadAction) {
      state.getLoading = true;
    },
    getSuccess(state, action: PayloadAction<RecentSearchCoach[]>) {
      state.coaches = action.payload;
      state.getLoading = false;
    },
    getError(state, action: PayloadAction<AxiosError>) {
      state.getLoading = false;
    },

    addRecentSearch(state, action: PayloadAction<AddRecentSearchAction>) {
      state.addLoading = true;
    },
    addRecentSearchSuccess(state, action: PayloadAction<RecentSearch>) {
      if (action.payload.coach_id) {
        state.coaches = [action.payload as RecentSearchCoach, ...state.coaches];
      }
      state.addLoading = false;
    },
    addRecentSearchError(state, action: PayloadAction<AxiosError>) {
      state.addLoading = false;
    },
  },
});

export const { actions: recentSearchesActions } = slice;

export const useRecentSearchesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: recentSearchesSaga });
  return { actions: slice.actions };
};
