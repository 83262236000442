import styled from 'styled-components/macro';

import { palette } from '../../../styles/tools';
import { Palette } from '../../../styles/tools/types';
import ifProp from '../../../styles/tools/ifProp';

export const Label = styled.label<{
  palette?: Palette;
  $noMargin?: boolean;
}>`
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  color: ${palette('label', 'default', 0)};
  letter-spacing: 0.8px;
  margin-bottom: ${ifProp('$noMargin', 0, 0.125)}rem;
  cursor: inherit;
  overflow-wrap: normal;

  form & {
    margin-bottom: 0.5rem;
  }
`;
