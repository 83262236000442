import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

import { palette, theme, withProp } from '../../../styles/tools';
import { Col, Row } from '../Grid';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { media } from '../../../styles/media';

export const NavBar = memo(() => {
  return (
    <Wrapper>
      <Row>
        <Col xs={12} display={{ xs: 'none', lg: 'block' }}>
          <DesktopNav />
        </Col>
        <Col xs={12} display={{ xs: 'block', lg: 'none' }}>
          <MobileNav />
        </Col>
      </Row>
    </Wrapper>
  );
});

const Wrapper = styled.header`
  padding: 6px 16px var(--safe-area-inset-bottom, 6px);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  border-top: 1px solid ${theme('global.background')};
  backdrop-filter: blur(25px);
  background-color: ${withProp(palette('card', 'default'), background =>
    rgba(background, 0.8),
  )};

  ${media.lg} {
    padding: 14px 16px;
    background: ${palette('card', 'default')};
    position: static;
    border-top: none;
    backdrop-filter: none;
  }

  @media print {
    display: none;
  }
`;
