import prop from './prop';
import type { Needle, PropsFn } from './types';

export const withProp =
  (needle: Needle | Needle[], fn: Function): PropsFn =>
  (props = {}) => {
    if (Array.isArray(needle)) {
      const needles = needle.map(arg => withProp(arg, x => x)(props));
      return fn(...needles);
    }
    if (typeof needle === 'function') {
      return fn(needle(props));
    }
    return fn(prop(needle)(props));
  };

export default withProp;
