import React from 'react';
import styled from 'styled-components/macro';
import { Label } from '../Label';
import { theme } from '../../../styles/tools';
import { media } from '../../../styles/media';

interface Props {
  children: any;
  title?: string;
}

export function SubArticle(props: Props) {
  const { children, title } = props;

  return (
    <Wrapper>
      <SubArticleTitle>{title}</SubArticleTitle>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0.75rem;
  border: 1px solid ${theme('palette.article.primary.0')};
  border-radius: 0.5rem;
  position: relative;
  break-inside: avoid;

  ${media.md} {
    padding: 1rem;
  }
`;

export const SubArticleTitle = styled(Label)`
  position: absolute;
  padding: 0 0.25rem;
  left: 0.5rem;
  top: -0.25rem;
  background: ${theme('palette.card.default.0')};
  color: ${theme('palette.heading.default.0')};

  ${media.md} {
    padding: 0 0.5rem;
    left: 1.5rem;
  }
`;
