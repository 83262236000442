import styled from 'styled-components/macro';
import ReactTooltipOrigin from 'react-tooltip';

import { palette } from '../../../styles/tools';
import { media } from '../../../styles/media';

export const Tooltip = styled(ReactTooltipOrigin)`
  &&& {
    padding: 0.25rem 0.5rem;
    border: 0.5px solid ${palette('tooltip', 'default', 0)};
    background: ${palette('tooltip', 'default', 1)};
    font-size: 16px;
    line-height: 24px;
    color: ${palette('tooltip', 'default', 2)};
    max-width: 80vw;
    border-radius: 0.25rem;
    backdrop-filter: blur(0.5rem);
    white-space: pre-line;

    ${media.md} {
      max-width: 400px;
    }

    &:before,
    &:after {
      display: none;
    }
  }
`;
