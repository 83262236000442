import { createGlobalStyle } from 'styled-components';
import { multiSelectStyles } from '../app/components/Select/styles';

export const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    print-color-adjust: exact;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background: ${props => props.theme.global.background};
    color: ${props => props.theme.global.text};
    font-size: 16px;
  }

  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 20rem;

    @media print {
      min-width: 1112px;
    }
  }

  body {
    font-size: 17px;
    line-height: 24px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &.fontLoaded {
      font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0;

    & + & {
      margin-top: 1rem;
    }
  }

  ul, ol {
    padding-inline-start: 1.25rem;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  @page {
    margin: 1.5rem 1rem;
    size: portrait;
  }

  @media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  .react-select__menu-portal {
    z-index: 3 !important;

    ${multiSelectStyles}
  }

  .ReactVirtualized__Grid {
    margin: -0.5rem;
    padding: 0.5rem;

    @media print {
      height: auto !important;
      overflow: visible !important;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: clip !important;
    overflow-clip-margin: 8px !important;

    @media print {
      height: auto !important;
      overflow: visible !important;
    }

    & > div {
      @media print {
        position: relative !important;
        top: 0 !important;
        height: auto !important;
      }
    }
  }
`;
