import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink } from './NavLink';
import { Icon } from '../Icon';
import { Nav } from './components/Nav';

export function MobileNav() {
  const { t } = useTranslation();

  return (
    <Nav>
      <NavLink to="/profile" tags={['coach']}>
        <Icon name="person" />
        <br />
        {t('navigation.profile')}
      </NavLink>
      <NavLink to="/" tags={['!coach']} exact>
        <Icon name="logo" />
        <br />
        {t('navigation.home')}
      </NavLink>
      <NavLink to="/coaches" permissions={['access_coaches']}>
        <Icon name="person" />
        <br />
        {t('navigation.coaches')}
      </NavLink>
      <NavLink to="/collections" permissions={['access_collections']}>
        <Icon name="discover" />
        <br />
        {t('navigation.discover')}
      </NavLink>
      <NavLink to="/shortlists" permissions={['access_shortlists']}>
        <Icon name="bookmark" />
        <br />
        {t('navigation.shortlists')}
      </NavLink>
      <NavLink to="/search" permissions={['access_coaches']}>
        <Icon name="search" />
        <br />
        {t('navigation.search')}
      </NavLink>
    </Nav>
  );
}
