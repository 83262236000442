import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { pageSettingsSaga } from './saga';
import { PageSettingsState } from './types';
import { PageSettingsItem } from '../../../../types/PageSettings';

export const initialState: PageSettingsState = {
  settings: [],
  getLoading: false,
  updateLoading: false,
};

const slice = createSlice({
  name: 'pageSettings',
  initialState,
  reducers: {
    getSettings: state => {
      state.getLoading = true;
    },
    getSettingsSuccess: (state, action: PayloadAction<PageSettingsItem[]>) => {
      state.getLoading = false;
      state.settings = action.payload;
    },
    getSettingsError: state => {
      state.getLoading = false;
    },
    updateSettings: (
      state,
      action: PayloadAction<{ settings: PageSettingsItem[] }>,
    ) => {
      state.updateLoading = true;
      state.settings = action.payload.settings;
    },
    updateSettingsSuccess: state => {
      state.updateLoading = false;
    },
    updateSettingsError: state => {
      state.updateLoading = false;
    },
  },
});

export const { actions: pageSettingsActions } = slice;

export const usePageSettingsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: pageSettingsSaga });
  return { actions: slice.actions };
};
