import React, { PropsWithChildren } from 'react';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { useThemeSlice } from './slice';
import { selectTheme } from './slice/selectors';

export const ThemeProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const isSystemDark = useMedia('(prefers-color-scheme: dark)');
  const theme = useSelector(state => selectTheme(state, { isSystemDark }));

  useThemeSlice();

  return (
    <OriginalThemeProvider theme={theme}>{children}</OriginalThemeProvider>
  );
};
