import React, { PropsWithChildren } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import {
  ifProp,
  palette,
  PaletteProps,
  prop,
  switchProp,
  theme,
} from '../../../styles/tools';
import { IconWrapper } from '../Icon';
import { media } from '../../../styles/media';

type CommonProps = PaletteProps & {
  icon?: 'start' | 'end' | 'center';
  target?: string;
  $loading?: boolean;
  block?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => any;
  style?: React.CSSProperties;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  invalid?: boolean;
  textTransform?: 'none' | 'uppercase';
  $minimized?: boolean;
};

type Props = Partial<
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled' | 'type'>
> &
  Partial<Pick<React.LinkHTMLAttributes<HTMLLinkElement>, 'href'>> &
  Partial<Pick<LinkProps, 'to'>> &
  CommonProps;

export const Button = (props: PropsWithChildren<Props>) => {
  const { to, href } = props;

  if (to) return <StyledRouterLink {...(props as LinkProps & CommonProps)} />;
  if (href) return <StyledLink {...props} />;
  return <StyledButton {...props} />;
};

Button.defaultProps = {
  type: 'button',
  height: 40,
  $loading: false,
  block: false,
};

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const buttonStyles = css`
  display: ${ifProp('block', 'flex', 'inline-flex')};
  align-items: center;
  white-space: nowrap;
  justify-content: ${prop('justifyContent', 'center')};
  text-decoration: none;
  cursor: ${ifProp(
    ({ disabled, palette }) => disabled || palette === 'active',
    'default',
    'pointer',
  )};
  appearance: none;
  box-sizing: border-box;
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  background: ${palette('button', 'default', 0)};
  color: ${palette('button', 'default', 2)};
  border: none;
  text-transform: ${prop('textTransform', 'uppercase')};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: ${ifProp('textTransform', 0, 1)}px;
  position: relative;
  width: ${ifProp('block', '100%', 'auto')};
  padding: ${ifProp('$minimized', '0.25rem 0.75rem', '10px 18px')};
  border-radius: ${ifProp('$minimized', '100px', '6px')};
  ${ifProp(
    '$loading',
    css`
      color: transparent !important;
    `,
  )}
  ${ifProp(
    ({ icon }) => icon === 'center',
    css`
      flex-direction: column;
    `,
  )};

  ${media.lg} {
    padding: 10px 18px;
    border-radius: 6px;
  }

  @media print {
    display: none;
  }

  ${ifProp(
    ({ palette }) => palette !== 'active',
    css`
      &:hover {
        background: ${palette('button', 'default', 1)};
        transition: background-color 100ms ease-out;
        color: ${palette('button', 'default', 3)};
      }
    `,
  )}

  ${ifProp(
    'invalid',
    css`
      background: ${theme('palette.button.invalid.0')};
      color: ${theme('palette.button.invalid.2')};

      &:hover {
        background: ${theme('palette.button.invalid.1')};
        color: ${theme('palette.button.invalid.3')};
      }
    `,
  )}

  &[disabled] {
    color: ${theme('palette.button.disabled.2')};
    pointer-events: none;
    ${ifProp(
      ({ palette }) => palette !== 'default',
      css`
        background: ${theme('palette.button.disabled.0')};
      `,
    )}

    svg {
      opacity: ${ifProp('$loading', 0, 0.4)};
    }
  }

  ${IconWrapper} {
    svg {
      width: 1rem;
      height: 1rem;
    }

    ${switchProp(
      prop('icon'),
      {
        start: css`
          margin-right: 6px;
        `,
        end: css`
          margin-left: 6px;
        `,
        center: css`
          display: block;
          margin-bottom: 6px;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        `,
      },
      '',
    )};
  }

  &:after {
    ${ifProp(
      '$loading',
      css`
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.2);
        border-top-color: #fff;
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      `,
    )}
  }
`;

export const StyledRouterLink = styled(Link)<CommonProps>`
  ${buttonStyles}
`;

export const StyledLink = styled.a<CommonProps>`
  ${buttonStyles}
`;

export const StyledButton = styled.button<CommonProps>`
  ${buttonStyles}
`;
