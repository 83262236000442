import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { recentSearchesActions as actions } from '.';
import { api } from '../../../../services/api';
import { RecentSearchCoach } from './types';

function* getCoaches(action: PayloadAction) {
  try {
    const response: AxiosResponse<{
      total: number;
      data: RecentSearchCoach[];
    }> = yield call(api.get, `/recent-searches`, {
      params: {
        take: 12,
        type: 'coach',
      },
    });
    yield put(actions.getSuccess(response.data.data));
  } catch (err) {
    const error = err as AxiosError;

    yield put(actions.getError(error));
  }
}

function* addRecentSearch(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse<RecentSearchCoach> = yield call(
      api.post,
      `/recent-searches`,
      action.payload,
    );
    yield put(actions.addRecentSearchSuccess(response.data));
  } catch (err) {
    const error = err as AxiosError;

    yield put(actions.addRecentSearchError(error));
  }
}

export function* recentSearchesSaga() {
  yield takeLatest(actions.getCoaches.type, getCoaches);
  yield takeLatest(actions.addRecentSearch.type, addRecentSearch);
}
