import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { pageSettingsActions as actions } from '.';
import { api } from '../../../../services/api';
import { PageSettingsItem } from '../../../../types/PageSettings';

function* getSettings() {
  try {
    const response: AxiosResponse<PageSettingsItem[]> = yield call(
      api.get,
      '/page-sections-settings',
    );

    yield put(actions.getSettingsSuccess(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.getSettingsError());
  }
}

function* updateSettings(
  action: PayloadAction<{ settings: PageSettingsItem[] }>,
) {
  try {
    yield call(api.post, '/page-sections-settings', action.payload);

    yield put(actions.updateSettingsSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.updateSettingsError());
  }
}

export function* pageSettingsSaga() {
  yield takeLatest(actions.getSettings.type, getSettings);
  yield takeLatest(actions.updateSettings.type, updateSettings);
}
