import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import FontFaceObserver from 'fontfaceobserver';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { Capacitor } from '@capacitor/core';
import { useAsync } from 'react-use';

import { GlobalStyle } from 'styles/global-styles';

import { RoutesList } from './routes';
import { AuthContext } from '../context/AuthContext';
import { selectLoaded } from './slice/selectors';
import { useDictSlice } from './slice';
import { GoogleTag } from './google-tag';
import { CustomCoach } from './components/CustomCoach/Loadable';
import { Welcome } from './components/Welcome/Loadable';
import { useProfileSlice } from './pages/SettingsPage/Profile/slice';
import { NavBar } from './components/NavBar';
import { useReportSectionsSlice } from './pages/ReportConstructorPage/ReportSections/slice';
import { bootIntercom, shutdownIntercom } from '../services/intercom';
import { initUsersnap } from '../services/usersnap';
import { usePushNotifications } from '../hooks/usePushNotifications';
import { usePageSettingsSlice } from './components/ExpandedSections/slice';
import { useFavoriteTeamsSlice } from './components/FavoriteTeams/slice';

const fontObserver = new FontFaceObserver('Source Sans Pro', {});
const tagId = process.env.GTM_TAG;

fontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

export function App() {
  const { i18n } = useTranslation();
  const { isAuthenticated, user, loadingUserData } = useContext(AuthContext);
  const loaded = useSelector(selectLoaded);
  const dispatch = useDispatch();
  const { actions } = useDictSlice();
  const { actions: profileActions } = useProfileSlice();
  const { actions: reportSectionActions } = useReportSectionsSlice();
  const { breakpoints } = useTheme();
  const isLg = useMediaQuery(breakpoints.up('lg'));
  const { initPushNotifications, stopPushNotifications } =
    usePushNotifications();
  const { actions: pageSettingsActions } = usePageSettingsSlice();
  const { actions: favoriteTeamsActions } = useFavoriteTeamsSlice();
  const theme = useTheme();

  useAsync(async () => {
    if (Capacitor.isNativePlatform()) {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    }

    const safeAreaData = await SafeArea.getSafeAreaInsets();
    const { insets } = safeAreaData;
    for (const [key, value] of Object.entries(insets)) {
      if (value) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`,
        );
      }
    }
  });

  useEffect(() => {
    if (isAuthenticated && !loaded) {
      dispatch(actions.getDict());
      dispatch(profileActions.getUser());
      dispatch(reportSectionActions.getReportSections());
      dispatch(favoriteTeamsActions.getFavoriteTeams());
      !isLg && dispatch(pageSettingsActions.getSettings());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    isLg && initUsersnap();

    if (!loadingUserData) {
      if (isAuthenticated) {
        initPushNotifications();
        isLg && bootIntercom(user);
      } else {
        stopPushNotifications();
        shutdownIntercom();
      }
    }
  }, [isAuthenticated, loadingUserData]);

  return (
    <>
      <Helmet
        titleTemplate="%s"
        defaultTitle="COACHINSIDE"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="" />
        <meta
          name="theme-color"
          content={theme?.global?.background || '#181B1A'}
        />
      </Helmet>
      {isAuthenticated && <NavBar />}
      <RoutesList />
      <CustomCoach />
      {isAuthenticated && !user?.lastloggedin ? <Welcome /> : null}
      <GlobalStyle />
      <GoogleTag tagId={tagId} />
    </>
  );
}
