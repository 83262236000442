import React from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Option } from '../Select/types';
import { StyledButton } from '../Button';
import { Col, Row } from '../Grid';
import { Tooltip } from '../Tooltip';
import { Loader } from '../Loader';
import { palette } from '../../../styles/tools';

interface TabOptions<T> extends Option<T> {
  hint?: string;
  disabled?: boolean;
}

type CommonProps<T> = {
  options: TabOptions<T>[];
  value: Option<T> | undefined;
  small?: boolean;
  $loading?: boolean;
  palette?: 'default' | 'primary';
  wrap?: boolean;
};

type UnselectableProps<T> = CommonProps<T> & {
  unselectable: true;
  onChange: (option: Option<T> | null) => void;
};

type SelectableProps<T> = CommonProps<T> & {
  unselectable?: false;
  onChange: (option: Option<T>) => void;
};

export function Tabs<T = number | string>(
  props: UnselectableProps<T> | SelectableProps<T>,
) {
  const {
    options,
    value: tabsValue,
    onChange,
    small,
    unselectable,
    $loading,
    palette,
    wrap,
  } = props;

  return (
    <TabsWrapper palette={palette}>
      <Row
        gutter={0.25}
        gutterY={0.25}
        alignContent="stretch"
        flexWrap={wrap ? 'wrap' : 'nowrap'}
      >
        {options.map(option => {
          const { label, value, hint, disabled } = option;
          const id = uuidv4();
          const active = tabsValue && tabsValue.value === value;

          return (
            <Col
              key={`${value}`}
              gutter={0.25}
              gutterY={0.25}
              flex="1 0 auto"
              style={{ minWidth: 'auto' }}
            >
              <TabButton
                data-tip
                data-for={id}
                active={active}
                block
                onClick={() => {
                  if (unselectable && value === tabsValue?.value) {
                    // @ts-ignore
                    onChange(null);
                  } else if (value !== tabsValue?.value) {
                    onChange(option);
                  }
                }}
                small={small}
                $loading={active && $loading}
                type="button"
                palette={palette}
                disabled={disabled}
              >
                {label}
              </TabButton>
              {hint ? (
                <Tooltip id={id} effect="solid">
                  {hint}
                </Tooltip>
              ) : null}
            </Col>
          );
        })}
      </Row>
    </TabsWrapper>
  );
}

export const TabsWrapper = styled.div<{ palette?: 'default' | 'primary' }>`
  padding: 0.25rem;
  background: ${palette('tabs', 'primary', 0)};
  border-radius: 6px;
  position: relative;

  ${Loader} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:after {
      width: 20px;
      height: 20px;
      margin: -10px 0 0 -10px;
      border-width: 2px;
    }
  }
`;

const TabButton = styled(StyledButton)<{ active?: boolean; small?: boolean }>`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-height: 100%;
  color: ${palette('tabs', 'primary', 1)};
  background: transparent;
  white-space: normal;

  @media print {
    display: inline-flex;
  }

  &:hover {
    background: ${palette('tabs', 'primary', 2)};
    color: ${palette('tabs', 'primary', 3)};
  }

  ${ifProp(
    'active',
    css`
      background: ${palette('tabs', 'primary', 4)} !important;
      color: ${palette('tabs', 'primary', 5)} !important;
      cursor: default;
    `,
  )}

  ${ifProp(
    'small',
    css`
      font-size: 13px;
      line-height: 16px;
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      background: transparent !important;
      opacity: 0.6;
    `,
  )}
`;
