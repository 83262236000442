import styled, { css } from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import React, { PropsWithChildren, useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { TabsWrapper } from '../Tabs';
import {
  ifProp,
  palette,
  PaletteProps,
  prop,
  theme,
} from '../../../styles/tools';
import { SubArticleTitle } from '../SubArticle';

type Props = PaletteProps & {
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  padding?: number;
  target?: string;
  style?: React.CSSProperties;
};

export const cardStyles = css`
  padding: ${prop('padding', 14)}px;
  border: 2px solid ${palette('card', 'default', 0)};
  background: ${palette('card', 'default', 0)};
  border-radius: 0.5rem;
  box-shadow: ${palette('card', 'default', 3)};
  display: block;
  break-inside: avoid;

  ${TabsWrapper} {
    background: ${theme('palette.tabs.default.0')};
  }

  ${SubArticleTitle} {
    background: ${palette('card', 'default', 0)};
  }
`;

export const styledLinkCard = css`
  ${cardStyles}

  &:hover {
    border-color: ${palette('card', 'default', 1)};
    transition: border-color 100ms ease-out;
  }
`;

export const StyledRouterLinkCard = styled(RouterLink)<PaletteProps>`
  ${styledLinkCard}
`;

export const StyledHrefCard = styled.a`
  ${styledLinkCard}
`;

export const StyledCard = styled.div`
  ${cardStyles}

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;

      &:hover {
        border-color: ${palette('card', 'default', 1)};
        transition: border-color 100ms ease-out;
      }
    `,
  )}
`;

export function Card(props: PropsWithChildren<Props>) {
  const { to, padding, target, onClick } = props;

  let origin: string | undefined = undefined;
  try {
    origin = to ? new URL(to)?.origin : undefined;
  } catch (error) {}

  const onOpenExternalLink = useCallback(
    async event => {
      onClick && onClick(event);

      if (Capacitor.isNativePlatform()) {
        event.preventDefault();
        await Browser.open({ url: to as string, presentationStyle: 'popover' });
      }
    },
    [origin],
  );

  if (to) {
    if (origin) {
      return (
        <StyledHrefCard
          {...props}
          href={to}
          target={Capacitor.isNativePlatform() ? '_self' : '_blank'}
          rel="nofollow noreferrer"
          onClick={onOpenExternalLink}
        />
      );
    } else {
      return <StyledRouterLinkCard {...props} to={to} target={target} />;
    }
  }

  return <StyledCard {...props} />;
}
