import styled from 'styled-components/macro';

export const Nav = styled.div`
  display: flex;
  margin: 0 -8px;
  justify-content: space-around;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
