import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface HistoryItem {
  path: string;
  label: string;
}

interface Props {}

interface Context {
  pushHistory: (value: HistoryItem) => void;
  setHistory: (values: HistoryItem[]) => void;
  history: HistoryItem[];
}

export const HistoryContext = createContext<Context>({} as Context);

export function HistoryProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const location = useLocation();
  const { t } = useTranslation();

  const collectionsPage = useRouteMatch(`/collections`);
  const collectionPage = useRouteMatch(`/collection/:id`);
  const shortlistsPage = useRouteMatch(`/shortlists`);
  const shortlistPage = useRouteMatch(`/shortlist/:id`);
  const coachesPage = useRouteMatch(`/coaches`);
  const searchPage = useRouteMatch(`/search`);

  const pushHistory = (value: HistoryItem) => {
    const index = history.findIndex(({ path }) => path === value.path);

    if (index === -1) {
      setHistory([...history, value]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const path = location.pathname;

    if (collectionsPage?.path) {
      setHistory([{ path, label: t('navigation.discover') }]);
    } else if (collectionPage?.path) {
      pushHistory({
        path,
        label: t('navigation.collection'),
      });
    } else if (shortlistsPage?.path) {
      setHistory([{ path, label: t('navigation.shortlists') }]);
    } else if (shortlistPage?.path) {
      pushHistory({
        path,
        label: t('navigation.shortlist'),
      });
    } else if (coachesPage?.path) {
      setHistory([
        {
          path,
          label: t('navigation.coaches'),
        },
      ]);
    } else if (searchPage?.path) {
      setHistory([
        {
          path,
          label: t('pageTitle.search'),
        },
      ]);
    } else if (location.pathname.split('/').length <= 2) {
      setHistory([]);
    }
  }, [location.pathname]);

  const filtered = history.filter(({ path }) => path !== location?.pathname);

  return (
    <HistoryContext.Provider
      value={{ history: filtered, pushHistory, setHistory }}
    >
      {children}
    </HistoryContext.Provider>
  );
}
