import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { reportSectionsActions as actions } from '.';
import { api } from '../../../../../services/api';
import {
  CreateReportSectionAction,
  DeleteReportSectionAction,
  UpdateReportSectionAction,
} from './types';
import { ReportSectionEntry } from '../../types';

function* getReportSections() {
  try {
    const response: AxiosResponse<{
      total: number;
      data: ReportSectionEntry[];
    }> = yield call(api.get, '/report-sections');

    yield put(actions.getReportSectionsSuccess(response.data.data));
  } catch (err) {
    yield put(actions.getReportSectionsError());
  }
}

function* createReportSection(
  action: PayloadAction<CreateReportSectionAction>,
) {
  const { callback, reportSection } = action.payload;

  try {
    const response: AxiosResponse<ReportSectionEntry> = yield call(
      api.post,
      '/report-sections',
      reportSection,
    );

    yield put(actions.createReportSectionSuccess(response.data));
    callback && callback(response.data.id);
  } catch (error) {
    console.error(error);
    yield put(actions.createReportSectionError());
  }
}

function* updateReportSection(
  action: PayloadAction<UpdateReportSectionAction>,
) {
  const { callback, id, reportSection } = action.payload;

  try {
    const response: AxiosResponse<ReportSectionEntry> = yield call(
      api.patch,
      `/report-sections/${id}`,
      reportSection,
    );

    yield put(actions.updateReportSectionSuccess(response.data));
    callback && callback(response.data.id);
  } catch (error) {
    console.error(error);
    yield put(actions.updateReportSectionError());
  }
}

function* deleteReportSection(
  action: PayloadAction<DeleteReportSectionAction>,
) {
  const { callback, id } = action.payload;

  try {
    yield call(api.delete, `/report-sections/${id}`);
    yield put(actions.deleteReportSectionSuccess(id));
  } catch (error) {
    console.error(error);
    yield put(actions.deleteReportSectionError());
  } finally {
    callback && callback();
  }
}

export function* reportSectionsSaga() {
  yield takeLatest(actions.getReportSections.type, getReportSections);
  yield takeLatest(actions.createReportSection.type, createReportSection);
  yield takeLatest(actions.updateReportSection.type, updateReportSection);
  yield takeLatest(actions.deleteReportSection.type, deleteReportSection);
}
