import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';

import { api } from '../services/api';
import {
  createFCMToken,
  getFCMToken,
  removeFCMToken,
} from '../utils/auth-cookies';
import { AuthContext } from '../context/AuthContext';

const mobileDebug = false;

const deviceDebug = (message: any) => {
  if (mobileDebug) {
    alert(message);
  }
};

export function usePushNotifications() {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const registration = useCallback(async ({ value }: Token) => {
    try {
      let token = value;
      if (Capacitor.getPlatform() === 'ios') {
        const { token: fcm_token } = await FCM.getToken();
        token = fcm_token;
      }

      deviceDebug('Registered: ' + token);
      await api.post('/fcm', { fcm_token: token });

      const storedToken = await getFCMToken();
      if (storedToken && storedToken !== token) {
        await api.delete(`/fcm/${storedToken}`);
      }

      createFCMToken(token);
    } catch (error: any) {
      deviceDebug('Error on registration: ' + error.message);
      console.error(error);
    }
  }, []);

  const registrationError = useCallback((error: any) => {
    deviceDebug('Error on registration: ' + JSON.stringify(error));
  }, []);

  const pushNotificationActionPerformed = useCallback(
    (event: ActionPerformed) => {
      try {
        const notification = event.notification as PushNotificationSchema;
        const url = notification?.data?.url;
        deviceDebug(notification.data.url);
        if (url) {
          history.replace(url);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const addNotificationsListeners = useCallback(async () => {
    await PushNotifications.addListener('registration', registration);
    await PushNotifications.addListener('registrationError', registrationError);

    // Method called when tapping on a notification
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      pushNotificationActionPerformed,
    );
  }, []);

  const initPushNotifications = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) return;

    PushNotifications.requestPermissions().then(async permission => {
      if (permission.receive === 'granted') {
        await addNotificationsListeners();
        await PushNotifications.register();
      } else {
        alert('You can always turn on notifications in the settings');
      }
    });
  }, []);

  const stopPushNotifications = async () => {
    if (!Capacitor.isNativePlatform()) return;

    removeFCMToken();
    await PushNotifications.removeAllDeliveredNotifications();
    await PushNotifications.removeAllListeners();
    await PushNotifications.unregister();
  };

  return {
    initPushNotifications,
    stopPushNotifications,
  };
}
