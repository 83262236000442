import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { favoriteTeamsActions as actions } from '.';
import { AxiosResponse } from 'axios';
import { FavoriteTeam, Team } from '../../../../types/Team';
import { api } from '../../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* getFavoriteTeams() {
  try {
    const response: AxiosResponse<FavoriteTeam[]> = yield call(
      api.get,
      '/favorite-teams',
    );
    yield put(actions.getFavoriteTeamsSuccess(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.getFavoriteTeamsError());
  }
}

function* addFavoriteTeam(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse<FavoriteTeam> = yield call(
      api.post,
      '/favorite-teams',
      {
        team_id: action.payload,
      },
    );
    yield put(actions.addFavoriteTeamSuccess(response.data));
  } catch (error) {
    console.error(error);
    yield put(actions.addFavoriteTeamError());
  }
}

function* deleteFavoriteTeam(action: PayloadAction<number>) {
  try {
    yield call(api.delete, `/favorite-teams/${action.payload}`);
    yield put(actions.deleteFavoriteTeamSuccess(action.payload));
  } catch (error) {
    console.error(error);
    yield put(actions.deleteFavoriteTeamError(action.payload));
  }
}

export function* favoriteTeamsSaga() {
  yield takeLatest(actions.getFavoriteTeams.type, getFavoriteTeams);
  yield takeLatest(actions.addFavoriteTeam.type, addFavoriteTeam);
  yield takeLatest(actions.deleteFavoriteTeam.type, deleteFavoriteTeam);
}
