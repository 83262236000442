import { css } from 'styled-components';
import { theme } from '../../../styles/tools';
import { media } from '../../../styles/media';

export const searchSelectStyles = css`
  padding: 0.25rem;
  border-radius: 0.75rem 0.75rem 0 0;

  &.is-focused {
    background: ${theme('palette.select.search.2')};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.3);
  }

  .react-select__control {
    min-height: 44px;
    background-color: ${theme('palette.select.search.0')};
    font-size: 15px;
    line-height: 18px;
    box-shadow: none;
    border-radius: 0.5rem;
    transition: none;
    border: none;

    &:hover {
      outline: 1px solid ${theme('palette.input.default.2')} !important;
    }

    &.react-select__control--menu-is-open {
      &:hover {
        outline: none !important;
      }

      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    svg {
      margin-left: 14px;
    }
  }

  .react-select__value-container {
    padding: 2px 14px 2px 10px;
  }

  .react-select__placeholder {
    color: ${theme('palette.select.search.4')};
  }

  .react-select__single-value {
    color: ${theme('palette.select.search.5')};
  }

  .react-select__input-container {
    color: ${theme('palette.select.search.5')};
  }

  .react-select__indicator {
    color: ${theme('palette.select.search.9')} !important;

    &.react-select__clear-indicator {
      &:hover {
        color: ${theme('palette.select.search.6')} !important;
      }
    }

    &.react-select__dropdown-indicator {
      transition: transform 0.2s;
    }
  }

  .react-select__menu {
    background: ${theme('palette.select.search.2')};
    margin: 0;
    border-radius: 0 0 0.75rem 0.75rem;
    font-size: 17px;
    line-height: 24px;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
    width: auto;
    left: 0;
    right: 0;
    top: 100%;
    padding: 4px;
  }

  .react-select__group-heading {
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 0 1rem;
    color: ${theme('palette.label.default.0')};
  }

  .react-select__menu-list {
    padding: 0;
    max-height: calc(100vh - 13rem);

    ${media.md} {
      max-height: calc(100vh - 15rem);
    }

    ${media.lg} {
      max-height: 26rem;
    }
  }

  .react-select__option {
    padding: 0;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0.5rem;

    &:active,
    &.react-select__option--is-focused {
      background: ${theme('palette.select.search.7')};
    }

    &.react-select__option--is-selected {
      background: ${theme('palette.select.search.8')};
    }
  }

  .react-select__loading-indicator {
    color: ${theme('palette.select.search.9')};
  }
`;
